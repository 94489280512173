import { Skeleton, BlockHeading } from '@leaf/ui';
import { MediaAnnouncement, MediaUpdate } from '@/apollo/generated';
import DiscoverCard from '@/components/home/discover-card';

interface Props {
  heading?: string;
  latestAnnualReport: MediaAnnouncement;
  latestPresentation: MediaAnnouncement;
  latestQuarterlyCashflowReport: MediaAnnouncement;
  latestUpdate: MediaUpdate;
  loading: boolean;
}

const Discover: React.ComponentType<Props> = ({
  heading = 'Discover',
  latestAnnualReport,
  latestPresentation,
  latestQuarterlyCashflowReport,
  latestUpdate,
  loading,
}) => {
  const renderDiscoverCards = () => (
    <div className="flex flex-col gap-4">
      <BlockHeading>{heading}</BlockHeading>
      <div className="grid grid-cols-1 gap-4 md:grid md:grid-cols-2 md:gap-6 lg:grid-cols-3">
        {loading ? (
          <>
            <Skeleton loading height={316} variant="rect" />
            <Skeleton loading height={316} variant="rect" />
            <Skeleton loading height={316} variant="rect" />
          </>
        ) : (
          <>
            {latestAnnualReport && (
              <DiscoverCard
                announcement={latestAnnualReport}
                layout="vertical"
              />
            )}
            {latestPresentation && (
              <DiscoverCard
                announcement={latestPresentation}
                layout="vertical"
              />
            )}
            {latestUpdate ? (
              <DiscoverCard layout="vertical" update={latestUpdate} />
            ) : latestQuarterlyCashflowReport ? (
              <DiscoverCard
                announcement={latestQuarterlyCashflowReport}
                layout="vertical"
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="mx-auto w-full max-w-screen-xl space-y-6 px-4 md:px-6">
      {renderDiscoverCards()}
    </div>
  );
};

export default Discover;
