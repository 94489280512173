export const StartCursor = Buffer.from('arrayconnection:-1').toString('base64');

export function calculateStartCursor(
  page: number,
  rowsPerPage: number,
  setStartCursor: React.Dispatch<React.SetStateAction<string>>
) {
  const nextCursor = rowsPerPage * (page - 1) - 1;
  setStartCursor(
    Buffer.from(`arrayconnection:${nextCursor}`).toString('base64')
  );
}

export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case 'GBP':
      return '£';
    case 'AUD':
      return '$';
    case 'GBX':
    default:
      return '';
  }
}
