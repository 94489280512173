import { Typography } from '@leaf/ui';
import { BannerHeader } from '@/components/svg/banner-header';

interface HeaderProps {
  headerText: string;
}

const PageHeader: React.ComponentType<HeaderProps> = ({ headerText }) => {
  return (
    <div className="relative">
      <BannerHeader />
      <div className="relative mx-auto flex max-w-screen-xl items-center justify-center p-4 sm:h-[100px] sm:justify-start sm:px-6 md:h-[160px]">
        <Typography
          className="text-center text-company-primary-text sm:text-left"
          variant="medium"
        >
          {headerText}
        </Typography>
      </div>
    </div>
  );
};

export default PageHeader;
