import { useMemo } from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { Typography, Skeleton, Card, BlockHeading } from '@leaf/ui';
import { useMediaUpdateDateRangeQuery } from '@/apollo/generated';
import { useCurrentCompany } from '@/contexts/current-company-context';
import routes from '@/utils/routes';

const QuickLinks: React.ComponentType = () => {
  const {
    currentCompany: { hub },
    translate,
  } = useCurrentCompany();

  const { data, loading } = useMediaUpdateDateRangeQuery({
    variables: {
      hub,
    },
  });

  const quickLinks = useMemo(() => {
    const items = [
      {
        link: routes.announcements.href,
        title: translate('announcements.uppercase'),
      },
      {
        link: `${routes.announcements.href}#annual-reports`,
        title: 'Annual reports',
      },
      {
        link: `${routes.announcements.href}#presentations`,
        title: 'Presentations',
      },
    ];

    if (data?.mediaUpdateDateRange?.newest) {
      items.push({
        link: routes.activityUpdates.href,
        title: 'Updates',
      });
    }
    return items;
  }, [data?.mediaUpdateDateRange?.newest, translate]);
  return (
    <div className="mx-auto w-full max-w-screen-xl space-y-4 px-4 md:px-6">
      <BlockHeading>Quick links</BlockHeading>

      {loading && <Skeleton loading height={46} variant="rect" width="100%" />}
      <div className="grid gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-4">
        {!loading &&
          quickLinks.map((item) => (
            <Link key={item.title} href={item.link}>
              <Card className="group flex h-full w-full items-center justify-between p-6">
                <Typography className="text-company-primary" variant="button">
                  {item.title}
                </Typography>
                <ArrowRightIcon className="h-5 w-5 text-company-primary transition-transform group-hover:translate-x-1/4 " />
              </Card>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default QuickLinks;
