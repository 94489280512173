import { CSSProperties } from 'react';
const standardBannerStyles: CSSProperties = {
  background: 'none',
  border: 0,
  bottom: 0,
  boxSizing: 'border-box',
  display: 'block',
  height: 'initial',
  left: 0,
  margin: 0,
  opacity: 1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: 'initial',
};

const mediaAnnouncementStyles: CSSProperties = {
  background: 'none',
  border: '0px',
  boxSizing: 'border-box',
  display: 'block',
  height: 'initial',
  inset: '0px',
  margin: '0px',
  opacity: 1,
  overflow: 'hidden',
  padding: '0px',
  position: 'absolute',
  width: 'initial',
};

interface BannerHeaderProps {
  isMediaAnnouncement?: boolean;
}

export const BannerHeader = ({ isMediaAnnouncement }: BannerHeaderProps) => {
  const primaryStyles = isMediaAnnouncement
    ? mediaAnnouncementStyles
    : standardBannerStyles;

  return (
    <span
      style={{ ...primaryStyles, backgroundColor: 'var(--company-primary)' }}
    />
  );
};
