import clsx from 'clsx';
import { Typography } from '@leaf/ui';

export enum SymbolVariant {
  Medium = 'medium',
  Small = 'small',
}

interface SymbolProps {
  className?: string;
  variant?: SymbolVariant;
  withBorder?: boolean;
}

interface ChartLegendProps {
  translate: (
    key: string,
    params?:
      | {
          [key: string]: string | number;
        }
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
}

export const SharePriceSymbol = ({
  className,
  variant = SymbolVariant.Medium,
}: SymbolProps) => (
  <span
    className={clsx(
      'bg-chart-blue-med',
      variant === SymbolVariant.Small && 'h-1 w-3',
      variant === SymbolVariant.Medium && 'h-1 w-6',
      className && className
    )}
  ></span>
);

export const VolumeSymbol = ({ className }: SymbolProps) => (
  <span
    className={clsx('h-3 w-3 bg-secondary-grey-light', className && className)}
  ></span>
);

export const AnnouncementsSymbol = ({
  className,
  withBorder = false,
}: SymbolProps) => (
  <span
    className={clsx(
      'h-3 w-3 rounded-full bg-primary-grey-dark',
      withBorder && 'border border-white',
      className && className
    )}
  ></span>
);

export const ChartLegend = ({ translate }: ChartLegendProps) => (
  <div className="mb-6 w-full select-none justify-center space-y-4 px-8 xs:flex xs:flex-row xs:space-x-10 xs:space-y-0">
    <div className="flex flex-row items-center space-x-4">
      <VolumeSymbol />
      <Typography component="span" variant="subtitle-2">
        Volume
      </Typography>
    </div>
    <div className="flex flex-row items-center space-x-4">
      <SharePriceSymbol
        className="inline xs:hidden"
        variant={SymbolVariant.Small}
      />
      <SharePriceSymbol className="hidden xs:inline" />
      <Typography
        className="whitespace-nowrap"
        component="span"
        variant="subtitle-2"
      >
        Share price
      </Typography>
    </div>
    <div className="flex flex-row items-center space-x-4">
      <AnnouncementsSymbol />
      <Typography component="span" variant="subtitle-2">
        {translate('announcements.uppercase')}
      </Typography>
    </div>
  </div>
);
