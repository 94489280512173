import { initApollo } from '@/apollo/client';
import {
  CurrentCompanyDocument,
  CurrentCompanyQuery,
} from '@/apollo/generated';
import Home from '@/components/home';
import { PREVIEW_COOKIE_NAME } from '@/components/utils/constants';
import Website from '@/components/website';
import { withCurrentCompanyContext } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';
import { withPreviewCookie } from '@/utils/middlewares';

interface Props {
  isUK: boolean;
}

const IndexPage: React.ComponentType<Props> = ({ isUK }) => {
  const { currentWebsite } = useWebsite();

  if (currentWebsite) {
    return <Website />;
  }
  return <Home isUK={isUK} />;
};

export const getServerSideProps = withPreviewCookie(async (context) => {
  try {
    const apolloClient = initApollo({ cookie: context.req.headers.cookie });

    const { data } = await apolloClient.query<CurrentCompanyQuery>({
      query: CurrentCompanyDocument,
      variables: {
        currentPageSlug: 'home',
        host: context.params?.host,
      },
    });

    if (data.currentCompany) {
      return {
        props: {
          ...context.params,
          currentCompany: data.currentCompany,
          isPreviewMode: context.req.cookies[PREVIEW_COOKIE_NAME] !== undefined,
          isUK: ['aqse', 'lse'].includes(data.currentCompany.ticker.marketKey),
        },
      };
    }

    return {
      notFound: true,
    };
  } catch (e) {
    return {
      notFound: true,
    };
  }
});

export default withCurrentCompanyContext(IndexPage);
