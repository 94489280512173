import { Typography, Skeleton } from '@leaf/ui';
import { MediaAnnouncement } from '@/apollo/generated';
import DiscoverCard from '@/components/home/discover-card';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { FLAGS } from '@/hooks/use-feature-toggles';

interface Props {
  announcements: MediaAnnouncement[] | undefined;
  children?: React.ReactNode;
  loading: boolean;
}

const LatestAnnouncements: React.ComponentType<Props> = ({
  announcements,
  children,
  loading,
}) => {
  const {
    currentCompany: { featuresEnabled },
    currentCompanyMarketKey,
    currentCompanyTicker,
    translate,
  } = useCurrentCompany();

  const isWebsitePageBuilderMode = featuresEnabled.includes(
    FLAGS.websitePageBuilder
  );

  const renderAnnouncements = () => (
    <div className="grid grid-cols-1 gap-4 md:grid md:grid-cols-2 md:gap-6">
      {announcements?.map((announcement) => (
        <DiscoverCard
          key={announcement.id}
          announcement={announcement as MediaAnnouncement}
        />
      ))}
    </div>
  );

  const renderEmptyState = () =>
    !loading ? (
      <Typography className="text-text-secondary">
        <em>{`No ${translate(
          'announcements.lowercase'
        )} found for ${currentCompanyMarketKey}:${currentCompanyTicker}.`}</em>
      </Typography>
    ) : (
      <Skeleton height={170} loading={loading} variant="rect" />
    );

  return (
    <div className="mx-auto flex w-full max-w-screen-xl flex-col gap-8 px-4 md:px-6">
      {isWebsitePageBuilderMode ? (
        children
      ) : (
        <Typography className="text-text-main" variant="medium">
          Latest {translate('announcements.lowercase')}
        </Typography>
      )}
      {announcements && announcements.length > 0
        ? renderAnnouncements()
        : renderEmptyState()}
    </div>
  );
};

export default LatestAnnouncements;
