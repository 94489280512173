import { useMemo } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { SuperRichTextEditor } from '@leaf/ui';
interface Props {
  content: string | undefined;
  plaintext?: boolean;
}

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

const RichContent: React.ComponentType<Props> = ({ content, plaintext }) => {
  // For homepage cards so the summary/update content isn't styled
  const blockContentToPlaintextHtml = () => {
    const blocks = JSON.parse(content || '{}').blocks || [];
    const plaintextHtml = blocks
      .map((block: { text: string }) => {
        return `<p class="text-hubs-primary">${block.text}</p>`;
      })
      .join('');

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(plaintextHtml),
        }}
        className="inline children:break-words"
      />
    );
  };

  const isRichContent = useMemo(() => {
    if (!content) {
      return false;
    }
    try {
      const parsed = JSON.parse(content);
      return !!parsed?.blocks?.length;
    } catch (_e) {
      return false;
    }
  }, [content]);

  if (isRichContent) {
    if (plaintext) return blockContentToPlaintextHtml();

    return <SuperRichTextEditor readOnly content={content} />;
  }

  if (!content) return null;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content),
      }}
      className="children:break-words"
    />
  );
};

export default RichContent;
