import WebsiteLayout from '@/components/website/layout/website-layout';
import WebsitePage from '@/components/website/page';
import { useWebsite } from '@/contexts/website-context';

const Website: React.FC = () => {
  const { currentWebsite } = useWebsite();

  return (
    <WebsiteLayout>
      <WebsitePage page={currentWebsite?.currentPage} />
    </WebsiteLayout>
  );
};

export default Website;
