import analytics from '@analytics';
import { ArrowRightIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { Button, Typography } from '@leaf/ui';
import { useCurrentInvestorUserQuery } from '@/apollo/generated';
import { AnnouncementBannerLogo } from '@/components/layouts/root/logo';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { FLAGS } from '@/hooks/use-feature-toggles';
import routes from '@/utils/routes';

interface BannerComponentProps {
  buttonText: string;
  buttonTextResponsive: string;
  description: string;
  external: boolean;
  href: string;
  onClickTrackingEvent: string;
}

interface WrapperProps {
  children: React.ReactNode;
  external: boolean;
  href: string;
  onClickTrackingEvent?: string;
}
const Wrapper: React.FC<WrapperProps> = ({
  children,
  external,
  href,
  onClickTrackingEvent,
}) => {
  if (external) {
    return (
      <a
        href={href}
        rel="noreferrer noopener"
        target="_blank"
        onClick={
          onClickTrackingEvent
            ? () => analytics.track(onClickTrackingEvent)
            : undefined
        }
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        href={href}
        onClick={
          onClickTrackingEvent
            ? () => analytics.track(onClickTrackingEvent)
            : undefined
        }
      >
        {children}
      </Link>
    );
  }
};

const BannerComponent: React.FC<BannerComponentProps> = ({
  buttonText,
  buttonTextResponsive,
  description,
  external,
  href,
  onClickTrackingEvent,
}) => {
  const {
    currentCompany: {
      logoUrl,
      ticker: { listingKey, marketKey },
    },
  } = useCurrentCompany();

  return (
    <Wrapper
      external={external}
      href={href}
      onClickTrackingEvent={onClickTrackingEvent}
    >
      <div className="fixed bottom-0 z-20 w-full cursor-pointer bg-company-primary text-company-primary-text sm:h-20">
        <div className="relative mx-auto flex max-w-screen-xl items-center justify-center gap-4 py-4 sm:justify-start sm:space-y-0 sm:p-4 sm:px-6">
          <div className="hidden items-center justify-center sm:block">
            {/* A hot fix for open learning logo - TBR with a proper cropping system */}
            <AnnouncementBannerLogo
              listingKey={listingKey}
              logoUrl={logoUrl}
              marketKey={marketKey}
            />
          </div>
          <Typography
            className="hidden text-center sm:block sm:text-left"
            variant="heading-5"
          >
            {description}
          </Typography>
          <div className="flex flex-1 items-center justify-center md:justify-end">
            <Button
              className="hidden sm:flex"
              color="white"
              endIcon={<ArrowRightIcon className="h-6 w-6" />}
              variant="primary"
            >
              {buttonText}
            </Button>
            <Button
              className="sm:hidden"
              color="white"
              endIcon={<ArrowRightIcon className="h-6 w-6" />}
              variant="primary"
            >
              {buttonTextResponsive}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const SignUpBanner = () => {
  const {
    currentCompany: { name },
  } = useCurrentCompany();
  return (
    <BannerComponent
      buttonText="Join our investor hub"
      buttonTextResponsive="Join our investor hub"
      description={`Be the first to get notified about ${name} news, updates, and announcements.`}
      external={false}
      href={routes.auth.signup.href()}
      onClickTrackingEvent="join_investor_hub_cta_sticky_banner_clicked"
    />
  );
};

const Banner: React.FC<{ hideSignUp?: boolean }> = ({ hideSignUp }) => {
  const {
    currentCompany: {
      featuresEnabled,
      sppBannerButtonText,
      sppBannerDescription,
      sppBannerMobileButtonText,
      sppUrl,
    },
  } = useCurrentCompany();

  const { data: currentUserData, loading: currentUserLoading } =
    useCurrentInvestorUserQuery();

  // Visitor - SPP enabled n SPP banner description filled - show SPP banner
  // Visitor - SPP enabled n SPP banner description not filled - show Signup banner
  // Visitor - SPP not enabled - show Signup banner
  // Logged in - SPP enabled n SPP banner description filled - show SPP banner
  // Logged in - SPP enabled n SPP banner description not filled - hide banner
  // Logged in - SPP not enabled - hide banner

  if (!hideSignUp && !currentUserLoading) {
    if (
      sppUrl &&
      sppBannerButtonText &&
      sppBannerDescription &&
      sppBannerMobileButtonText &&
      featuresEnabled.includes(FLAGS.sppEnabled)
    ) {
      // SPPBanner
      return (
        <BannerComponent
          external
          buttonText={sppBannerButtonText}
          buttonTextResponsive={sppBannerMobileButtonText}
          description={sppBannerDescription}
          href={sppUrl}
          onClickTrackingEvent="spp_banner_clicked"
        />
      );
    }

    if (!currentUserData?.currentInvestorUser) {
      return <SignUpBanner />;
    }
  }

  return null;
};

export default Banner;
